import React, { useState, useEffect} from "react";
import { Tab, Tabs, Row, Col } from "react-bootstrap";
import api from "../../actions/riveroApi";
import { navigate } from "gatsby";

import { Separador } from "./miscelaneos";
import CarCardCarrousel from "../layouts/CarCardCarrousel";

export default function TabsModelosCarrousel(props){

    const [autos, filterAutos] = useState([]);

    const [suvs, filterSuvs] = useState([]);

    const [pickVan, filterPickVan] = useState([]);

    const [depElectrico, filterDepElec] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getCarros();
    }, []);

    function getCarros(e) {
        const atributos = {
            filter: e || "",
            orderBy: "precio:asc",
            ultimoModelo: true,
            perPage: 100,
            mostRecent: true,
        };
        setIsLoading(true);

        api.getCars(atributos).then((e) => e.json()).then((array) => {
            console.log(array,"Array CArros:");
            /* Filtrado Autos Disponibles */
            const filterImage = array.data.filter((obj) => { return obj.image !== null && obj.make == "NISSAN" ; })
            /* Filtrado para cada seccion */
            
            let autos = filterImage.filter(carro => carro.type === "autos");
            let suvs = filterImage.filter(carro => carro.type === "suvs");
            let pickupVan = filterImage.filter(carro => carro.type === "pickups");
            let depElectricos = filterImage.filter(carro => carro.type === "deportivos");
            /* Impresion de cada seccion filtrada */
            filterAutos(autos);
            filterSuvs(suvs);
            filterPickVan(pickupVan);
            filterDepElec(depElectricos);

            //console.log("HOLA HE LLEGADO: ", filterImage);
            setIsLoading(false);
        })
            .catch((error) => { console.error(error); });
    }

    function onSelectedPasos(info){
        //console.log("Recibo datos: ", info)
        navigate(`/nissan/${info.slug}`)
    }

    return(
        <>
            <div >
                <br />
                <center>
                    <h1 style={{fontSize: 25}}>Agencias Nissan en Monterrey</h1>
                    <h4>Autos Nuevos</h4>
                </center>
                <div className="mt-4 mb-2"><Separador /></div>
            </div>
            <Tabs defaultActiveKey="autos" id="filterAutos" className="tabs " fill style={{color: 'black'}}>

                <Tab eventKey="autos" title="Autos" className="content nav-gracias-autos">
                    <Row>
                        <VistaCarrosCarrousel onSelectedPasos={onSelectedPasos} post={autos} isLoading={isLoading} props={props} />
                    </Row>
                </Tab>
                <Tab eventKey="suvs" title="Suvs" className="content ">
                    <Row>
                        <VistaCarrosCarrousel onSelectedPasos={onSelectedPasos} post={suvs} isLoading={isLoading} props={props} />
                    </Row>
                </Tab>
                <Tab eventKey="pickupVan" title="Pickup y Van" className="content ">
                    <Row>
                        <VistaCarrosCarrousel onSelectedPasos={onSelectedPasos} post={pickVan} isLoading={isLoading} props={props} />
                    </Row>
                </Tab>
                <Tab eventKey="deportivosElectricos" title="Deportivos y E-Power" className="content ">
                    <Row>
                        <VistaCarrosCarrousel onSelectedPasos={onSelectedPasos} post={depElectrico} isLoading={isLoading} props={props} />
                    </Row>
                </Tab>

            </Tabs>
        </>
    )

}

function VistaCarrosCarrousel({onSelectedPasos, post}){

    const mappingUnidades = post?.map((obj, i) => {
        return(
            <Col lg={3} key={i} className="p-2 modal-ColUnidadesTipos-3pasos">
                <CarCardCarrousel
                    image={obj.image}
                    name={obj.name}
                    title={obj.model}
                    year={obj.year}
                    brand={obj.make}
                    price={obj.price}
                    model={obj.model}
                    info={obj}
                    onSelectedPasos={onSelectedPasos}
                />
            </Col>
        )
    })

    return(
        <Row>
            {mappingUnidades}
        </Row>
    )

}