import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaQuoteLeft } from "react-icons/fa";

import SEO from "../components/seo";
import api from "../actions/riveroApi";
import Layout from "../components/layouts/layout";
import Image from "../components/widgets/basico/image";
import { Separador } from "../components/widgets/miscelaneos";

export default function QuienesSomosNissan(props){

    const parameters = props.location.search;

    const [ data, setData ] = useState();

    useEffect(() => {
        api.getTestimonios().then((resp) => setData(resp.data)).catch((error) => console.log(error));
    }, [])

    const quienesSomos = [
        {titulo: "NUESTRA MISIÓN", img: "mision-nissan-rivero.jpg", descripcion: "Facilitamos tu movilidad para que todos vivamos mejor."},
        {titulo: "QUIENES SOMOS", img: "quienes-somos-nissan-rivero.jpg", descripcion: "Somos un equipo de más de 850 familias que persigue el mismo sueño: Que más familias vivan mejor. Contamos con más de 40 años en el mercado durante los cuales hemos cosechado múltiples logros que han impulsado nuestro continuo crecimiento, teniendo siempre como prioridad el apoyar a nuestros clientes a alcanzar sus objetivos de movilidad. Nuestro principal activo es y siempre será NUESTRA GENTE, ya que gracias a ellos se han cristalizado la mayor parte de nuestros sueños, contando con personal comprometido y capaz, que prioriza los valores, todos alineados bajo una misma misión y visión."}
    ];

    const agencias = [
        {titulo: "NISSAN RIVERO VALLE ORIENTE", img: "sucursal-nissan-rivero-las-torres.jpg"},
        {titulo: "NISSAN RIVERO CONTRY", img: "sucursal-nissan-rivero-contry.jpg"},
        {titulo: "NISSAN RIVERO VALLE", img: "sucursal-nissan-rivero-valle.jpg"},
        {titulo: "NISSAN RIVERO ALLENDE", img: "sucursal-nissan-rivero-allende.jpg"},
    ];

    const mappingQuienesSomos = quienesSomos.map((obj, i) => {
        return(
            <Col key={i}>
                {obj.titulo === "NUESTRA MISIÓN" ? (
                    <Col className="row p-2 m-0">
                        <Col md={6} className="text-center">
                            <div className="m-0">
                                {<Image fileName={obj.img} alt={obj.titulo}/> }
                            </div>
                        </Col>
                        <br />
                        <Col md={6} className="text-center">
                            <h1 className="titulo-quienes-somos mt-4">{obj.titulo}</h1>
                            <Separador/>
                            <p className="p-2 texto-quienes-somos">{obj.descripcion}</p>
                        </Col>
                    </Col>
                ) : (
                    <Col className="row p-2 m-0">
                        <Col md={6} className="text-center">
                            <h1 className="titulo-quienes-somos mt-4">{obj.titulo}</h1>
                            <Separador/>
                            <p className="p-2 pb-4 texto-quienes-somos">{obj.descripcion}</p>
                        </Col>
                        <br />
                        <Col md={6} className="text-center">
                            <div className="m-0">
                                {<Image fileName={obj.img} alt={obj.titulo}/>}
                            </div>
                        </Col>
                    </Col>
                )}
            </Col>
        )
    });

    const mappingSucursales = agencias.map((obj, i) => {
        return(
            <Col lg={6} md={6} className=" p-2 m-0" key={i}>
                { <Image fileName={obj.img} alt={obj.titulo}/> }
                <p>{obj.titulo}</p>
            </Col>
        )
    })

    const mappingTestimonios = data?.map((obj, i) => {
        return(
            <Col lg={6} md={12} key={i}>
                <div className="linea-quienes-somos" style={{width:'100%',height:'95%'}}>
                    <FaQuoteLeft className="icon-quote-quinesSomos"/>
                    <p className="mt-4 mb-4">{obj.testimonio}</p>
                    <p className="text-primary font-weight-bolder">{obj.nombre}</p>
                </div>
            </Col>
        )
    })

    return(
        <Layout selectedMenuItem={"quienes-somos"} parameters={parameters}>
            <SEO
                title="Rivero Nissan - Agencia Nissan - Quiénes somos"
                description="Rivero Nissan - Agencia Nissan - Nissan Monterrey, Nissan Las Torres, Nissan Contry, Nissan Valle, Nissan Allende - March, Versa, Sentra, Altima, Kicks, XTrail"
                keywords="Agencia Nissan, Agencia de autos, nissan rivero monterrey, Nissan Monterrey, Nissan Las Torres, Nissan Contry, Nissan Valle, Nissan Allende, Aveo, Versa, Sentra, Altima, Kicks, XTrail"
                path="/quienes-somos"
            />
            <div className="banner-quienes-somos-nissan">
                <div className="sombra-banner-quienes-somos">
                    <div className="mt-4 p-4">
                        <p className="mb-2">Bienvenidos a</p>
                        <h1 className="titulo-quienes-somos">GRUPO RIVERO</h1>
                        <div className="mt-4 mb-4"><Separador/></div>
                        <br></br>
                        <p className="texto-quienes-somos">La más grande e importante agencia Nissan en Monterrey. Nos especializamos en dar soluciones en cuestiones de crédito, confort y movilidad, de forma fácil, rápida y segura.</p>
                    </div>
                </div>
            </div>
            <Container className="bg-white p-4">
                {mappingQuienesSomos}
                <br/>
                <Col className="text-center pt-4">
                    {/* <p className="p-2">Llevamos más de 30 años en el mercado, manejando exclusivamente las marcas de General Motors, Chevrolet, Cadillac, Buick, GMC y con la representación de Performance Store con las marcas deportivas Corvette y Camaro, contamos con 5 sucursales en Monterrey y su área metropolitana.</p> */}
                </Col>
                <Row className="p-4">
                    {mappingSucursales}
                </Row>
                <br/>
                <div style={{backgroundColor: 'saddlebrown'}} hidden>
                    <Image fileName="banner-nissan-fleet-solutions.jpg" alt="Flotillas"/>  
                </div>
                {/* <Row>
                    <center>
                        <h3 className="titulo-quienes-somos">Lo que dice la gente</h3>
                        <Separador/>
                    </center>
                </Row>
                <Row>
                    <Col lg={4} md={4} sm={12}>
                        <Image fileName="primo-opinion-gris.png" alt="Primo Rivero"/>
                    </Col> 
                    <Col lg={8} md={8} sm={12} className="p-3">
                        <Row>
                            {mappingTestimonios}
                        </Row>
                    </Col>
                </Row> */}
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </Layout>
    )
    
}