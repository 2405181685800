import React, { useState } from "react";
import { Container,Row, Col, Button } from "react-bootstrap";
import Image from "../components/widgets/basico/image";
import FormularioBasico from "../components/widgets/formularios/FormularioBasico";
import { FaPhoneAlt, FaWhatsapp, FaRegEnvelope } from "react-icons/fa";

import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import { Separador } from "../components/widgets/miscelaneos";


  const agenciaLasTorres = { titulo: "Nissan Rivero Valle Oriente", direccion: "Av. Lázaro Cárdenas 2514 San Pedro Garza Garcia, NUEVO LEÓN 66200",  img: "sucursal-nissan-rivero-las-torres.jpg",  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.674092487089!2d-100.33005589999999!3d25.648939999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bf917626517f%3A0xfbb99d20a3fbbe64!2sNissan%20Rivero%20Las%20Torres!5e0!3m2!1ses!2smx!4v1704493423816!5m2!1ses!2smx" };
  const agenciaContry = { titulo: "Nissan Rivero Contry", direccion: "Av. Eugenio Garza Sada 3800, Mas Palomas (Valle de Santiago) Monterrey, NUEVO LEÓN 64780", img: "sucursal-nissan-rivero-contry.jpg", map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.1087190421126!2d-100.28514202477353!3d25.634516977431964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bfa1df343707%3A0x63f249c78c4935a7!2sAv.%20Eugenio%20Garza%20Sada%203800%2C%20M%C3%A1s%20Palomas%20(Valle%20de%20Santiago)%2C%2064780%20Monterrey%2C%20N.L.!5e0!3m2!1ses!2smx!4v1722980277064!5m2!1ses!2smx" };
  const agenciaValle = { titulo: "Nissan Rivero Valle", direccion: "Calzada Del Valle 110, Col Del Valle San Pedro Garza Garcia, NUEVO LEÓN 66220", img: "sucursal-nissan-rivero-valle.jpg", map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57545.13874962586!2d-100.4044898!3d25.6523543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bdedaa9dff53%3A0x5be04a9425f90c73!2sNissan%20Rivero%20Valle!5e0!3m2!1ses!2smx!4v1704493607361!5m2!1ses!2smx" };
  const agenciaAllende = { titulo: "Nissan Rivero Allende", direccion: "Carr Nacional 301, San Javier, Cd. Allende, NUEVO LEÓN 67350", img: "sucursal-nissan-rivero-allende.jpg", map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230544.10188074867!2d-100.3399874226813!3d25.463273142360467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x867d2deb8e064bc7%3A0xf0716a693d7a5556!2sNissan%20Rivero%20Allende!5e0!3m2!1ses!2smx!4v1718058000929!5m2!1ses!2smx" };

export default function Contacto(props) {

    const parameters = props.location.search;

    return (
        <Layout selectedMenuItem={"horario"} parameters={parameters}>
            <SEO
                title="Rivero Nissan - Agencia Nissan - Quiénes somos"
                description="Rivero Nissan - Agencia Nissan - Nissan Monterrey, Nissan Valle Oriente, Nissan Contry, Nissan Valle, Nissan Allende - March, Versa, Sentra, Altima, Kicks, XTrail"
                keywords="Agencia Nissan, Agencia de autos, nissan rivero monterrey, Nissan Monterrey, Nissan Valle Oriente, Nissan Contry, Nissan Valle, Nissan Allende, Aveo, Versa, Sentra, Altima, Kicks, XTrail"
                path="/contacto" />

            <div className="banner-quienes-somos-nissan">
                <div className="sombra-banner-quienes-somos-nissan">
                    <div className="mt-4 p-4">
                        <br/>
                        <br/>
                        <h1 className="titulo-quienes-somos">NISSAN RIVERO</h1>
                        <br></br>
                        <h4>Telefonos, Horarios y Direcciones</h4>
                    </div>
                </div>
            </div>

            <Container  className="p-4">
                <center>
                    <h2 className="text-white">Contacta a tu Asesor de Confianza</h2>
                </center>
                <div className="mt-4 mb-2"><Separador /></div>
            </Container>

            <Container >
                {/*  */}
               {/*  <Row>
                    <Col md={4}>
                        <a href="tel:8112571257" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-tel-icono"><FaPhoneAlt size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Teléfono</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>(81) 1257-1257</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href="https://wa.me/+52181116001600" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-wpp-icono"><FaWhatsapp size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Whatsapp</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>(81) 1600-1600</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                    <Col md={4}>
                        <a href="mailto:informes@gruporivero.com" >
                            <Row>
                                <Col md={12} className="pb-2">
                                    <div className="estilo-correo-icono"><FaRegEnvelope size={30} /></div>
                                </Col>
                                <Col md={12}>
                                    <center><p><strong>Correo</strong></p></center>
                                </Col>
                                <Col md={12}>
                                    <center><p>informes@gruporivero.com</p></center>
                                </Col>
                            </Row>
                        </a>
                    </Col>
                </Row> */}
            </Container>

            <>
                <Container className="bg-white mb-4 px-4 pt-4">
                    <Agencia props={agenciaLasTorres}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaContry}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaValle}/>
                    <ContHorarios/>
                </Container>
                <Container className="bg-white mb-4  px-4 pt-4">
                    <Agencia props={agenciaAllende}/>
                    <ContHorarios/>
                </Container>
            </>

            <Row className="bg-white p-4 d-md-block d-none">
                <Container style={{padding: '40px', width: 1140}}>
                    <Row>
                        <center className="pb-3">
                            <h1>Contáctanos</h1>
                            <Separador/>
                        </center>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <h6 className="p-4 bg-light" hidden>
                                Formulario de Contacto Nissan
                            </h6>
                            <FormularioBasico props={props} subject={'Contacto'} btnTitulo={'Enviar'}>
                                <div className="form-group pb-3">

                                </div>
                            </FormularioBasico>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            { <Image fileName={"quienes-somos-nissan-rivero"}/> } 
                        </Col>
                    </Row>
                </Container>
            </Row>

        </Layout>
    )
}

function Agencia({props}) {
    //console.log("Estamos en agencia", props);
    return(
        <Row>
            <Col md={7}>
                <Image fileName={props.img}/>
            </Col>
            <Col md={5}>
                <iframe src={props.map}
                    width="100%" height={247}
                    title="ContImgMap"
                    frameBorder={0} style={{border: 0}}
                    allowFullScreen aria-hidden="false" />
            </Col>
            <Col md={12} className="pt-3">
                <center>
                <h4>{props.titulo}</h4>
                <p>{props.direccion}</p>
                </center>
            </Col>
        </Row>
    )
}

function ContHorarios() {
    return (
        <Row>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Horario Ventas</h5>
                    <p>Lunes - Viernes 9:00 AM - 8:00 PM</p>
                    <p>Sábado 9:00 AM - 7:00 PM</p>
                    <p>Domingo 10:00 AM - 6:00 PM</p>
                </center>
            </Col>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Taller de Servicio</h5>
                    <p>Lunes - Viernes 7:30 AM - 7:00 PM</p>
                    <p>Sábado 7:30 AM - 2:00 PM</p>
                    <p>Domingo Cerrado</p>
                </center>
            </Col>
            <Col md={4} className="mb-2">
                <center>
                    <h5>Refacciones</h5>
                    <p>Lunes - Viernes 8:00 AM - 7:00 PM</p>
                    <p>Sábado 8:00 AM - 2:00 PM</p>
                    <p>Domingo Cerrado</p>
                </center>
            </Col>  
        </Row>
    )
}